<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <!--app-view-toolbar-->
      <app-view-toolbar
        :title="title"
        :showBackButton="true"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton">
      </app-view-toolbar>
    </v-container>
    <v-container class="mt-4 responsive-container-class">
      <v-form ref="form" lazy-validation>
        <v-text-field
          label="Namn"
          v-model="formValues.evn_str_name"
          placeholder="Stenård Rockfestival 2022"
          persistent-placeholder
          dense
          outlined
          background-color="white"
          :rules="[rules.required]">
        </v-text-field>
        <v-checkbox
          class="my-0 py-0"
          label="Publikt event"
          v-model="formValues.evn_bit_public">
        </v-checkbox>
        <v-checkbox
          class="my-0 py-0"
          label="Publikt spelschema"
          v-model="formValues.evn_bit_public_schedule">
        </v-checkbox>
        <v-row>
          <v-col cols="12" sm="6">
            <app-date-input
              label="Startdatum"
              v-model="formValues.evn_dat_start" />
          </v-col>
          <v-col cols="12" sm="6">
            <app-date-input
              label="Slutdatum"
              v-model="formValues.evn_dat_end" />
          </v-col>
        </v-row>
        <v-text-field
          class="mt-4"
          label="Plats"
          v-model="formValues.evn_str_location"
          placeholder="Älmhult"
          persistent-placeholder
          dense
          outlined
          background-color="white">
        </v-text-field>
      </v-form>

      <v-btn
        v-if="!isCreate"
        outlined
        color="red"
        class="mt-4"
        @click="onDelete"
        small>
        <v-icon left>mdi-trash-can-outline</v-icon>
        Ta bort event
      </v-btn>
    </v-container>

    <app-confirm
      :show="showDeleteConfirm"
      title="Ta bort eventet?"
      @onYes="onDeleteConfirm"
      @onNo="showDeleteConfirm = false">
    </app-confirm>
  </div>
</template>

<script>

import { onMounted, ref, reactive } from '@vue/composition-api';
import { DateTime } from 'luxon';
import useCore from '../../helpers/core';
import useValidation from '../../helpers/validation';
import ViewToolbar from '../../components/ViewToolbar.vue';
import Confirm from '../../components/Confirm.vue';
import DateInput from '../../components/DateInput.vue';

export default {
  name: 'eventPut',
  props: {
    id: {
      type: Number,
      required: false,
    }
  },
  components: {
    "app-view-toolbar": ViewToolbar,
    'app-confirm': Confirm,
    "app-date-input": DateInput,
  },
  setup({ id }) {
    const router = require('@/router/router').default;

    const { callApi, showMessage } = useCore();
    const { rules } = useValidation();

    const isCreate = !id;
    const showDeleteConfirm = ref(false);

    const dataLoaded = ref(false);
    const title = ref('Event');
    const form = ref(null);

    const formValues = reactive({
      evn_str_name: '',
      evn_dat_start: null,
      evn_dat_end: null,
      evn_bit_public: false,
      evn_bit_public_schedule: false,
      evn_str_location: '',
    });

    // Actions
    const onSave = () => {
      // Invalid? Exit
      const formIsValid = form.value.validate();

      if (!formIsValid) {
        showMessage('Formuläret är inte komplett ifyllt');
        return;
      }

      // Submit Create/Update
      const method = isCreate ? 'post' : 'put';
      const path = isCreate ? `/cms/events` : `/cms/events/${id}`;

      formValues.evn_dat_start = formValues.evn_dat_start || null;
      formValues.evn_dat_end = formValues.evn_dat_end || null;

      callApi({ method, path, data: formValues })
        .then((res) => {
          showMessage(`${res.evn_str_name} har sparats`);
          router.replace({ name: 'event' });
        })
        .catch((err) => {
          return err;
        });
    };
    const onDelete = () => {
      showDeleteConfirm.value = true;
    }
    const onDeleteConfirm = () => {
      
      callApi({ method: 'delete', path: `/cms/events/${id}` })
        .then(() => {
          showMessage('Event har tagits bort');
          router.replace({ name: 'event' });
        })
        .catch((err) => {
          return err;
        });
    };

    // Toolbar actions
    const toolbarButtons = [{ icon: 'check', name: 'Spara', fn: onSave }];
    const onToolbarButton = (button) => button.fn();

    // Lifecycle-methods
    const doOnMounted = () => {
      callApi({ method: 'get', path: `/cms/events/${id}` })
        .then((res) => {
          dataLoaded.value = true;
          // No record found; Exit
          if (res.noRecord) { return; }
          title.value = `Redigera ${res.evn_str_name}`;
          // Record found; Populate form
          formValues.evn_str_name = res.evn_str_name;
          formValues.evn_bit_public = res.evn_bit_public;
          formValues.evn_bit_public_schedule = res.evn_bit_public_schedule;
          formValues.evn_str_location = res.evn_str_location;

          // Set start and end date of event
          const evn_dat_start = DateTime.fromISO(res.evn_dat_start);
          if (evn_dat_start.isValid) {
            formValues.evn_dat_start = evn_dat_start.toISODate();
          }

          const evn_dat_end = DateTime.fromISO(res.evn_dat_end);
          if (evn_dat_end.isValid) {
            formValues.evn_dat_end = evn_dat_end.toISODate();
          }
        })
        .catch((err) => {
          router.replace({ name: 'event' });
          return err;
        });
    };
    onMounted(doOnMounted);

    return {
      dataLoaded,
      form,
      rules,
      title,
      isCreate,
      formValues,
      toolbarButtons,
      onToolbarButton,

      showDeleteConfirm,
      onDeleteConfirm,
      onDelete,
    };
  },
};
</script>

<style>

</style>